import axios from "axios";

export const MANAGEMENT ='v1/management/';
export const AUTOTAGGING ='v1/autotagging';
export const SIMILARITY ='v1/similarity';
export const SIMILARITY_V2 ='v2/similarity';
export const IMAGE_SEARCH ='v1/image-search';
export const TEXT_SEARCH ='v1/text-search';
export const TEXT_SEARCH_V2 ='v2/text-search';

//prod
// export const apiKey='QdHcs11jHq8WFv9hiEm3K5n1u0a6D77fi01Ae5Q1'; 
// export const IMAGEURL='https://d1uq9p6zwxxn84.cloudfront.net/ttdemo/'

//dev
export const apiKey='32Z4tGu2GI9zmSPH8aJg06KmAN1ljV0UaOBDOLnp'; 
export const IMAGEURL='https://d2e7srl1blypta.cloudfront.net/ttdemo/'
export let FILTERS =null;

export let SOURCE=axios.CancelToken.source()

export function resetSource(){

    SOURCE=axios.CancelToken.source()

}




export const SETFILTERS=(value)=>{
    FILTERS=value;
}



